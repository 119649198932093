import "./WidgetContainer.css" 
const { Card } = require("react-bootstrap");

const WidgetContainer = ({
  title,
  subtitle,
  children,
}) => {
  return (
    <Card>
      <Card.Header className="custom-card-header">{title}</Card.Header>
      <Card.Body>
        <div className="row justify-content-between flex-wrap">
        </div>

        {/* Subtitle is optional for flexability */}
        {subtitle && <Card.Subtitle>{subtitle}</Card.Subtitle>}

        <div className="overflow-auto">
          {children}
        </div>
      </Card.Body>
    </Card>
  );
}

export default WidgetContainer;
