import { HubConnectionBuilder } from "@microsoft/signalr";
import { LogLevel } from "@microsoft/signalr";

const createConnection = (userId) => {
  if (!userId) {
    throw new Error("UserId cannet be null or undefined");
  }
  const connection = new HubConnectionBuilder()
    .withUrl(process.env.REACT_APP_SMS_API_URL + "/api/signalr", {
      headers: {
        "x-signalr-user-id": userId,
        "x-functions-key": process.env.REACT_APP_SMS_API_KEY,
      },
    })
    .configureLogging(LogLevel.Information)
    .build();

  return connection;
};

export { createConnection };
