import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import './GroupingModeSelector.css';

// 'enum' for grouping modes
// Key is the name in code, Value is the text to be shown on the selector button thingimajig
export const GroupingMode = Object.freeze({
  Terminal: 'Terminal',
  //TODO: will implement group by "Client" & "Target" feature later once we have multiple clients
  // Client: 'Client',
  // Target: 'Target',
  Status: 'Connection State',
});

const GroupingModeSelector = ({ mode, onModeChanged }) => {
  return (
    <>
      <p className='fs-6 fw-semibold selectorTitle'>Group by</p>
      <Row className='gap-3 g-0 mb-3'>
        {Object.entries(GroupingMode).map(([key, value]) => (
          <Col key={key} xs='auto'>
            <Button
              className={`groupSelector rounded-pill py-1 px-3 shadow-none fw-semibold${value === mode ? ' selected' : ''}`}
              onClick={() => onModeChanged(value)}>
              {value}
            </Button>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default GroupingModeSelector;
