import React from 'react';
import axios from 'axios';
import { useState, useEffect, useCallback } from 'react';
import { Spinner, Table, Button } from 'react-bootstrap';
import WidgetContainer from '../../components/WidgetContainer';
import "./../DashboardPage.css";

const EventHistoryWidget = () => {
  const [eventSummary, setEventSummary] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const resultsPerPage = 10;

  const incPageNumber = useCallback(() => {
    setPageNumber(pageNumber + 1);
  }, [pageNumber]);
  const decPageNumber = useCallback(() => {
    setPageNumber(pageNumber - 1);
  }, [pageNumber]);

  const filterLast24hrs = (data) => {
    const filteredData = data.filter((item) => (new Date(item.eventDate).getTime() > Date.now() - 24 * 60 * 60 * 1000));
    // results from API are in (oldest to newest) order, so we need to reverse the array
    return filteredData.reverse();
  }

  useEffect(() => {
    const getWidgetData = async () => {
      const environment = process.env.REACT_APP_ENVIRONMENT;
      const targetUrl = process.env.REACT_APP_SMS_API_URL + "/api/widget-data/event-summary" + (environment ? `?environment=${environment}` : '');
      const response = await axios({
        method: "get",
        url: targetUrl,
        headers: {
          "Access-Control-Allow-Origin": "*",
          Accept: "*/*",
          "x-functions-key": process.env.REACT_APP_SMS_API_KEY,
        },
      });
      const filteredEvents = filterLast24hrs(response.data);
      setEventSummary(filteredEvents);
    }
    getWidgetData();
  }, [])

  if (!eventSummary) {
    return (
      <WidgetContainer title="Recently Connected Clients">
        <div className="d-flex align-items-center justify-content-center">
          <Spinner animation='border' />
        </div>
      </WidgetContainer>
    )
  }

  return (
    <WidgetContainer title="4000 Event History">
      <div>
        <Table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Event Count</th>
            </tr>
          </thead>
          <tbody>
            {eventSummary.length > 0 ? eventSummary.slice(pageNumber * resultsPerPage, pageNumber * resultsPerPage + resultsPerPage).map((event) =>
              <tr key={event.eventDate}>
                {/* formatting date to look like: YYYY-MM-DD HH:MM */}
                <td>{event.eventDate.replace('T', ' ').slice(0, 16)}</td>
                <td>{event.eventCount}</td>
              </tr>
            ) : <><tr><td className="no-data-text">No data</td></tr></>
            }
          </tbody>
        </Table>
      </div>
      {eventSummary.length > 0 && <>
        <div className='d-flex justify-content-between'>
        <Button disabled={pageNumber === 0} onClick={decPageNumber}>
          Previous</Button>
        <Button disabled={pageNumber === Math.ceil(eventSummary.length / resultsPerPage) - 1} onClick={incPageNumber}>
          Next</Button></div>
      </>}
    </WidgetContainer>
  );
}

export default EventHistoryWidget;