import React from 'react';
import { Navigate } from 'react-router-dom';
import { Button, Card } from 'react-bootstrap';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { authProvider } from '../Auth/authProvider';
import sortMarshalLogo_white from '../assets/SortMarshal_white.png';
import poweredByLogo_white from '../assets/PoweredByPuroLab_white.png';
import "./Login.css";

const LoginForm = () => (
  <AzureAD provider={authProvider}>
    {
      ({ login, authenticationState, error }) => {
        switch (authenticationState) {
          case (AuthenticationState.Authenticated):
            return (
              <Navigate to='/dashboard' />
            );
          default:
            return (
              <div className='loginButtonContainer'>
                {error && error.errorCode !== "token_renewal_error" && <Card.Text>An error occured during authentication, please try again!</Card.Text>}
                <Button className='loginButton' variant='light' onClick={login}>Login through active directory</Button>
              </div>
            );
        }
      }
    }
  </AzureAD>
);

const Login = () => (
  <div className='loginContainer'>
    <img className='sortMarshalLogo' src={sortMarshalLogo_white} alt="sortMarshalLogo" width={220} />
    <LoginForm />
    <img className='poweredByLogo' src={poweredByLogo_white} alt="poweredByDigitalLab" width={290} />
  </div>

);

export default Login;
