import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import PageHeader from "../components/PageHeader";
import GroupingModeSelector, { GroupingMode } from "./GroupingModeSelector";
import { GetClients } from "../IoTDevices/clients";
import ClientGroupDetails  from "./ClientGroup";
import { DataClientsToast } from "../SignalR/DataClientUpdates";
import './GroupingModeSelector.css';

const GroupClients = (groupBy, devices) => {
  const capitalizeWords = (text) => {
    return text
      .split(/\s+/)
      .map((word) => {
        if (word.length === 0) return word;

        const firstLetter = word[0];
        const rest = word.substring(1);
        return `${firstLetter.toUpperCase()}${rest}`;
      })
      .join(" ");
  };

  const groupedResult = {};
  for (const device of devices) {
    const key = capitalizeWords(device.GetGroupKey(groupBy));
    if (!groupedResult[key]) {
      groupedResult[key] = [];
    }
    groupedResult[key].push(device);
  }

  return groupedResult;
};


const ClientGroupView = ({ groupName, clients }) => {
  return (
    <>
      <ClientGroupDetails groupName={groupName} clients={clients}></ClientGroupDetails>
    </>
  );
};

const ClientGroups = ({ clients, groupMode }) => {
  const groupedClients = GroupClients(groupMode, clients);
  return Object.entries(groupedClients)
    .sort() // sort as group order is not guaranteed up to this point
    .map(([groupName, clientsInGroup]) => (
      <ClientGroupView
        key={groupName}
        groupName={groupName}
        clients={clientsInGroup}
      />
    ));
};

const storedGroup = (groupMode, defaultValue) => {
  const storedGroup = sessionStorage.getItem(groupMode);
  if (!storedGroup) {
    return defaultValue;
  }
  return storedGroup;
};

//TODO:Currently if connectorId is passed we can't go back to all clients, this will be fixed when filtering is implemented. Ticket: #14345
const ClientsPage = () => {
  const { connectorId } = useParams(); //Optional Param
  const GROUPING_MODE_KEY = "groupMode";
  const [groupMode, setGroupMode] = useState(
    storedGroup(GROUPING_MODE_KEY, GroupingMode.Terminal)
  );
  const [clientsLoaded, setClientsLoaded] = useState(false);
  const [clients, setClients] = useState([]);
  const [signalRData, setSignalRData] = useState({
    subject: "",
    eventType: "",
  });
  useEffect(() => {
    //Filtering only when connectorId is passed
    if ((signalRData.subject && signalRData.eventType) || !clientsLoaded) {
      if (signalRData.subject && signalRData.eventType && signalRData.eventType !== "deviceTwinUpdated") {
        //No need to fetch data again when received signalR message if eventype != "deviceTwinUpdated"
        //Only need to show toast message for "clientCreated" event since the UI only shows connectors that has at least one client
        const updatedClients = updateClientsInfo({
          eventType: signalRData.eventType,
          deviceId: signalRData.subject.slice(8),
          clients: clients
        });
        setClients([...updatedClients]);      
      } else {
        if (!connectorId) {
          GetClients().then((res) => {
            setClients(res);
          });
        } else {
          GetClients().then((res) => {
            const filteredClients = res.filter(
              (client) => client.connectorId === connectorId
            );
            setClients(filteredClients);
          });
        }
      }
      setClientsLoaded(true);
      sessionStorage.setItem(GROUPING_MODE_KEY, groupMode);
    }
  }, [signalRData]);

  return (
    <>
      <PageHeader title="Client Apps" />
      <DataClientsToast setSignalRData={setSignalRData} />
      <div id="content" className="p-4">
        <GroupingModeSelector mode={groupMode} onModeChanged={setGroupMode} />
        {(clientsLoaded && clients.length !== 0 && (
          <ClientGroups clients={clients} groupMode={groupMode} />
          // <ClientGroup clients={clients} />
        )) ||
          (clientsLoaded && clients.length === 0 && (
            <span>No IoT Hub clients at this time.</span>
          )) || (
            <div className="mt-4">
              <Spinner animation="border" role="status" />
            </div>
          )}
      </div>
    </>
  );
};

const updateClientsInfo = ({eventType, deviceId, clients}) => {
  if (eventType === "clientDeleted" ) {
    return clients.filter((client) => client.connectorId !== deviceId);
  } else {
    const updatedClients = clients.map((client) => {
      if (client.connectorId === deviceId) {
        if (eventType === "clientConnected") {
          client.isOnline = true;
        } else if (eventType === "clientDisconnected") {
          client.isOnline = false;
        }
        return client;
      }
      return client;
    });
    return updatedClients
  }
}

export default ClientsPage;
