import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAndroid, faApple, faWindows, faLinux } from '@fortawesome/free-brands-svg-icons'

const PlatformInfo = (platform) => {
    if (platform !== undefined) {
      if (platform.toLowerCase().includes("ios") || platform.toLowerCase().includes("macos")) {
        return (<>
            <FontAwesomeIcon icon={faApple} />  
        </>);
      } else if (platform.toLowerCase().includes("windows")) {
        return (<>
          <FontAwesomeIcon icon={faWindows} />
        </>);
      } else if (platform.toLowerCase().includes("android")) {
        return (<>
           <FontAwesomeIcon icon={faAndroid} />
        </>);
      } else if (platform.toLowerCase().includes("linux")) {
        return (<>
            <FontAwesomeIcon icon={faLinux} /> 
        </>);
      } else {
        return (<>{platform}</>);
      }
    } else {
      return (<>{platform}</>);
    }
  };

export default PlatformInfo;