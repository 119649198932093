import React, { useState } from "react";
import PageHeader from "../components/PageHeader";
import DeviceStatus from "../components/DeviceStatus";
import { DataClientDetailToast } from "../SignalR/DataClientDetailUpdates";
import { Spinner, Col, Row, Container } from "react-bootstrap";
import { faCheckCircle, faTimesCircle } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment/moment";
import "./ClientAppPage.css";
import PlatformInfo from "../components/PlatformInfo"

const ClientDetails = ({ client }) => {
  //TODO: The rows need better centering
  //This line is testing SonarCloud Thao will remove it
  return (
    <Container>
      <div className="container-fluid m-0 p-0">
        <div className="row">
          <Row>
            <Col className="m-1 col-5 col-lg-2 fs-7 fw-semibold text-truncate  p-0">
              ID
            </Col>
            <Col className="m-1 col-5 col-lg-3 fs-7 text-truncate">
              {client.clientId}
            </Col>
            <Col className="m-1 col-5 col-lg-2 fs-7 fw-semibold text-truncate m-0 p-0">
              Terminal
            </Col>
            <Col className="m-1 col-5 col-lg-3 fs-7 text-truncate">
              {client.terminalName} ({client.terminalId})
            </Col>
          </Row>

          <Row>
            <Col className="m-1 col-5 col-lg-2 fs-7 fw-semibold text-truncate justify-content-start m-0 p-0">
              Friendly Name
            </Col>
            <Col className="m-1 col-5 col-lg-3 fs-7 text-truncate">
              {client.displayName}
            </Col>
            <Col className="m-1 col-5 col-lg-2 fs-7 fw-semibold text-truncate m-0 p-0">
              Selected Routes
            </Col>
            <Col className="m-1 col-5 col-lg-3 fs-7 text-truncate">
              {client.routes ||"None"}
            </Col>
          </Row>

          <Row className="justify-content-start">
            <Col className="m-1 col-5 col-lg-2 fs-7 fw-semibold text-truncate m-0 p-0">
              Client State
            </Col>
            <Col className="m-1 col-5 col-lg-3 fs-7 text-truncate">
              <DeviceStatus online={client.clientConnected} />
            </Col>
            <Col className="m-1 col-5 col-lg-2 fs-7 fw-semibold text-truncate m-0 p-0">
              Routes Filtered
            </Col>
            <Col className="m-1 col-5 col-lg-3 fs-7 text-truncate">
              {client.filteredRoutes !== undefined && client.filteredRoutes.toLowerCase() === "true" ?  
                <FontAwesomeIcon className="check-mark-icon" icon={faCheckCircle} /> : 
                <FontAwesomeIcon className="cancel-mark-icon" icon={faTimesCircle} />}
            </Col>
          </Row>

          <Row className="justify-content-start">
            <Col className="m-1 col-5 col-lg-2 fs-7 fw-semibold text-truncate m-0 p-0">
              Target
            </Col>
            <Col className="m-1 col-5 col-lg-3 fs-7 text-truncate">
              {client.target}
            </Col>
            <Col className="m-1 col-5 col-lg-2 fs-7 fw-semibold text-truncate m-0 p-0">
              Last Employee
            </Col>
            <Col className="m-1 col-5 col-lg-3 fs-7 text-truncate">
              {client.lastEmployee}
            </Col>
          </Row>
          <Row>
            <Col className="m-1 col-5 col-lg-2 fs-7 fw-semibold text-truncate m-0 p-0">
              Last Connected
            </Col>
            <Col className="m-1 col-5 col-lg-3 fs-7 text-truncate">
              {moment(client.lastConnected).format("DD/MM/YYYY - h:mma")}
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};

const ConnectorDetails = ({ client }) => {
  return (
    <Container>
      <p className="text-subtitle row">Device Info</p>
      <Row className="justify-content-start">
        <Col className="m-1 col-5 col-lg-2 fs-7 fw-semibold text-truncate m-0 p-0">
          Device ID
        </Col>
        <Col className="mt-1 col-5 col-lg-3 fs-7 text-truncate">
          {client.connectorId}
        </Col>
        <Col className="m-1 col-5 col-lg-2 fs-7 fw-semibold text-truncate m-0 p-0">
          Device Status
        </Col>
        <Col className="m-1 col-5 col-lg-3 fs-7 text-truncate">
          <DeviceStatus online={client.isDeviceOnline} />
        </Col>
      </Row>

      <Row className="justify-content-start">
        <Col className="m-1 col-5 col-lg-2 fs-7 fw-semibold text-truncate m-0 p-0">
          Operating System
        </Col>
        <Col className="col-5 col-lg-3 text-truncate platform-icon">
          {PlatformInfo(client.platform)}
        </Col>
        <Col className="m-1 col-5 col-lg-2 fs-7 fw-semibold text-truncate m-0 p-0">
          Date Registered
        </Col>
        <Col className="m-1 col-5 col-lg-3 fs-7 text-truncate">
          {"N/A"}
          {/* //TODO: API needs to provide this for device */}
        </Col>
      </Row>
    </Container>
  );
};

const OtherClientDetails = ({otherClients}) => {
  return (
    <Container>
      <p className="text-subtitle row">Other Clients Info</p>
      <Row className="header-row fw-semibold text-truncate justify-content-start">
        <Col>ID</Col>
        <Col>Client Type</Col>
        <Col>Status</Col>
      </Row>
      {otherClients.map((client, index) => {
          return (
            <a key={index} className="custom-hyperlink" href={`/clients/${client.connectorId}/${client.clientId}`}>
              <Row className="other-clients-row" key={index}>
                <Col>{client.clientId}</Col>
                <Col>{client.clientType}</Col>
                <Col><DeviceStatus online={client.clientConnected} /></Col>
              </Row>
            </a>
          );
        })}
    </Container>
  );
}

/**
 *  @param {{connector: import('../IoTDevices/clients').ConnectorDetail}} props
 */
const ClientDetailsPage = ({ client, otherClients }) => {
  const [toastMessage, setToastMessage] = useState(null);
  const noUnderlineStyle = { textDecoration: 'none' };

  const headerProps = {
    backlinkText:"Client Apps",
    backlinkTo:"/clients",
    title: client.displayName
  };

  return (
    <>
      <PageHeader {...headerProps} />
      <DataClientDetailToast deviceId={client.connectorId} />

      <div id="content" className="p-4">
        {(client && (
          <div>
            <ClientDetails client={client} />
            <hr className="my-4 container" />
            <ConnectorDetails client={client} />
            {(otherClients !== undefined && otherClients.length > 0) &&
              <>
                <hr className="my-4 container" />
                <OtherClientDetails otherClients={otherClients} />
              </>
            }
          </div>
        )) || <Spinner animation="border" role="status" />}
      </div>

      {/* <div className="connector-save-container d-flex align-items-center justify-content-center">
        <Link style={noUnderlineStyle} to={`/clients/${client.connectorId}`}>
          <button className="custom-button">Clients on Device</button>
        </Link>
      </div> */}
    </>
  );
};
export default ClientDetailsPage;
