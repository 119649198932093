import React, { useState, useEffect } from 'react';
import PageHeader from '../components/PageHeader';
import ReactMarkdown from 'react-markdown';
import GeneralInfoMd from './GeneralInfoMd.md';

const GeneralInfoPage = () => {
  const [generalInfo, setGeneralInfo] = useState("");
  useEffect(() => {
    // Reason why we're doing all this fetching (instead of importing a string) is to keep the info file as .md and not .jsx
    // This is to keep flexibility of md files and make them easily editable 
    fetch(GeneralInfoMd).then((response) => response.text()).then((text) => {
      setGeneralInfo(text);
    })
  }, [])
  return (
    <>
      <PageHeader title='General Info' />
      <div className="p-4">
        <ReactMarkdown>{generalInfo}</ReactMarkdown>
      </div>
    </>
  );
}

export default GeneralInfoPage;
