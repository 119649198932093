import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import './scss/global.scss';
import Login from './Login/Login';
import HomePage from './HomePage/HomePage';
import DashboardPage from './DashboardPage/DashboardPage';
import ProtectedRoutes from './ProtectedRoutes';
import Navigation from './Navigation/Navigation';
import ClientsPage from './ClientsPage/ClientsPage';
import ClientAppPage from './ClientDetailsPage/ClientAppPage';

import GeneralInfoPage from './portal/GeneralInfoPage';
import ApiReferencePage from './portal/ApiReferencePage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/login" element={<Login />} />

      <Route path="/" element={<Navigation />}>
        <Route index element={<HomePage />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/clients" element={<ClientsPage />} />
          <Route path="/clients/:connectorId/:clientId" element={<ClientAppPage />} />
          <Route path="/clients/:connectorId" element={<ClientsPage />} />
        </Route>

        <Route path="/developers" >
          <Route index element={<GeneralInfoPage />} />
          <Route path="api_reference" element={<ApiReferencePage />} />
        </Route>

      </Route>
    </Routes>
  </BrowserRouter>
);
