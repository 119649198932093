import React, { useState, useEffect, useRef } from "react";
import { FaAngleDown, FaTimes, FaSearch } from "react-icons/fa";
import "./DropdownSelector.css";

const DropdownTerminalSelector = ({
  options,
  onSaveSelection,
  initialValues = [],
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const ref = useRef(null);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleClearAll = () => {
    setSelectedOptions([]);
  };
  const handleOptionSelect = (option) => {
    if (selectedOptions.includes(option.id)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option.id));
    } else {
      setSelectedOptions([...selectedOptions, option.id]);
    }
  };
  const handleClearSelection = (option) => {
    setSelectedOptions(selectedOptions.filter((item) => item !== option));
  };
  const handleSaveSelection = () => {
    onSaveSelection(selectedOptions);
    setIsOpen(false);
  };

  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setIsOpen(false);
    }
  };

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    if (initialValues.length > 0 && selectedOptions.length === 0) {
      setSelectedOptions(initialValues);
    }
  }, [initialValues]);

  const renderOptions = () => {
    const filteredOptions = options.filter((option) =>
      option.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const selectedCount = selectedOptions.length;
    const shouldDisplayControls = options.length > 5; // Threshold for displaying controls

    return (
      <div>
        {shouldDisplayControls && (
          <div className="multi-select-controls">
            <span className="selected-count">{selectedCount} selected</span>
            <button className="clear-all-button" onClick={handleClearAll}>
              Clear All
            </button>
          </div>
        )}
        {filteredOptions.map((option) => (
          <div key={option.id} className="items-alignment">
            <input
              type="checkbox"
              id={option.id}
              value={option.name}
              checked={selectedOptions.includes(option.id)}
              onChange={() => handleOptionSelect(option)}
            />
            <label htmlFor={option.id}>
              {option.id} - {option.name}
            </label>
          </div>
        ))}
      </div>
    );
  };
  return (
    <div className="dropdown-container" ref={ref}>
      <div className={`dropdown ${isOpen ? "open" : ""}`}>
        <button className="dropdown-button" onClick={() => setIsOpen(!isOpen)}>
          <span className="span-style">
            {selectedOptions.length > 0 ? (
              selectedOptions.length < 3 ? (
                selectedOptions.map((option) => (
                  <span className="selected-item" key={option}>
                    {option}
                    <FaTimes
                      className="clear-icon"
                      onClick={() => handleClearSelection(option)}
                    />
                  </span> //Specific terminals were selected
                ))
              ) : (
                <span>. . .</span> //If more then 3 Terminals are selected
              )
            ) : (
              <span>All Terminals</span> //No terminals are selected -> we show all
            )}
          </span>
          <FaAngleDown className="dropdown-icon" />
        </button>

        {isOpen && (
          <div className="dropdown-options">
            <div className="search-input-container">
              <FaSearch className="search-icon" />
              <input
                id="search-input"
                type="text"
                value={searchQuery}
                onChange={handleSearchInputChange}
              />
            </div>

            {renderOptions()}

            <div className="save-button-container">
              <button className="save-button" onClick={handleSaveSelection}>
                Done
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DropdownTerminalSelector;
