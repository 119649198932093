import React, { useState } from 'react';
import { ReactComponent as ChevronLeft } from '../assets/chevron_left.svg';
import { Navigate } from 'react-router-dom';

import './PageHeader.css';

const Backlink = ({ linkText, onClick }) => {
  return (<>
    <div id='backlink' className='mb-2' onClick={onClick}>
      <div className='d-flex flex-nowrap align-items-center gap-2'>
        <ChevronLeft style={{ width: '0.75em', height: '1.25em' }} className='m-0 p-0 me-2' />
        <p className='m-0 p-0 fs-7 fw-semibold'>{linkText ?? 'Back'}</p>
      </div>
    </div>
  </>);
};

const PageHeader = ({ title, badge, subtitle, backlinkText, backlinkTo }) => {
  const [shouldGoBack, setGoBack] = useState(false);

  // can't use window.history.back() because the user may not be coming
  // from the connector page, but an external site
  if (shouldGoBack) {
    return (<Navigate to={backlinkTo ?? '/'} />);
  }

  return (<>
    <div className='headerContainer mt-4 p-4 pt-2 pb-3'>
      {(backlinkTo || backlinkText)
        && <Backlink linkText={backlinkText ?? 'Back'} onClick={() => setGoBack(true)} />}

      <div id='titleLine' className='d-flex flex-nowrap align-items-center'>
        <p id='title' className='fs-3 fw-bold m-0 p-0'>{title}</p>
        {badge && <div className={`${title && 'ms-4'} ps-1`}>{badge}</div>}
      </div>

      <div id='subtitleLine'>
        {subtitle && <p id='subtitle' className='fs-6 text-subtitle m-0 p-0 pt-1'>{subtitle}</p>}
      </div>

    </div>
  </>);
};

export default PageHeader;