import React from 'react';
import PageHeader from '../components/PageHeader';

const HomePage = () => (<>
  <PageHeader title='Homepage' />
  <div className="p-4">
    <p>Coming soon...</p>
  </div>
</>);

export default HomePage;
