import axios from 'axios';
import { GroupingMode } from '../ClientsPage/GroupingModeSelector';

class BaseClient {
  /**
   * @param {{ 
   *  connectorId: string;
   *  displayName: string;
   *  connectionState: 'Connected' | 'Disconnected';
   *  platform: string;
   *  terminalId: string;
   *  terminalName: string; 
   *  message: string;
   *  clients: [
   *    {
   *     clientId: string;
   *     displayName: string;
   *     clientType: string
   *    }
   * ] 
   * }} iotDevice
  */

  constructor(iotDevice) {
    if (iotDevice !== null) {
      const { connectorId, displayName, connectionState, platform, terminalName, terminalId, message } = iotDevice;
      const Clients = iotDevice.clients;
      this.connectorId = connectorId;
      this.displayName = displayName || 'Connector App';
      this.deviceName = displayName || connectorId;
      this.isOnline = connectionState === 'Connected';
      this.platform = platform?.charAt(0).toUpperCase() + platform?.slice(1);
      this.terminalName = terminalName || "Ottawa Terminal";
      this.terminalId = terminalId || '511';
      this.clients = Clients;
    }
  }

  /**
   * Gets a group key for this device under a given grouping mode.
   * @param {GroupingMode} groupingMode the active grouping mode
   */
  GetGroupKey(groupingMode) {
    switch (groupingMode) {
      case GroupingMode.Status: return this.isOnline ? "Online" : "Offline";
      case GroupingMode.Target: return this.target ?? "Unknown Target";
      case GroupingMode.Client: return this.clientType ?? "Unknown Client";
      case GroupingMode.Terminal: return (`${this.terminalName} (${this.terminalId})`)  ?? "No Terminal";
      default: throw Error(`Invalid grouping mode: ${groupingMode}`);
    }
  }
}

class ConnectorDetail extends BaseClient {
  /**
   * @param {{
   *  clients: [
   *   {
   *       clientId: string;
   *       displayName: string;
   *       clientType: string;    
   *       connected: boolean;
   *       lastConnected: string;
   *       clientDictionary: {
   *          terminalName: string;
   *          terminalId: string;
   *          target: string ;
   *          lastEmployee: string;
   *          routes: string;
   *          filteredRoutes: boolean;
   *          message: string;
   *          LastTimeConnectedAt: string;
   *          }
   *    }
   *  ]
   * }} iotDevice
     */

  // *  lastTimeConnected: string;

  constructor(iotDevice) {
    super(iotDevice);
    const { connectorId, connectionState, displayName, statusUpdateTime, lastConnected, lastTimeConnectedPointSort, platform } = iotDevice;
    const Clients = iotDevice.clients;
    this.lastTimeConnectedPointSort = lastTimeConnectedPointSort; // Temporary field to capture pointSort's LastTimeConnectedAt. Will be removed
    const ClientDictionary = Clients.map((device) => device.clientDictionary);
    this.connectorId = connectorId;
    this.deviceName = displayName || connectorId;
    this.isOnline = connectionState === 'Connected';
    this.clients = Clients;
    this.clientDictionary = ClientDictionary;
    this.platform = platform?.charAt(0).toUpperCase() + platform?.slice(1);
    this.statusUpdateTime = statusUpdateTime && new Date(statusUpdateTime);
    this.lastActivityTime = lastConnected && new Date(lastConnected);
    // we are getting "0001-01-01T00:00:00Z", 
    //which conversts as "December 31, 0000, 18:42" in local time zone

    //Temporary retriving pointsort's LastTimeConnectedAt value as Connectors last activity.
    if (Clients && Clients.length > 0) {
      const firstClient = Clients[0];
      const { clientDictionary } = firstClient;

      if (clientDictionary && clientDictionary.LastTimeConnectedAt) {
        this.lastTimeConnectedPointSort = clientDictionary.LastTimeConnectedAt;
      }
    }
  }
}

class PointSortClient extends BaseClient {
  /**
   * @param {{
   *   connectorId: string;
   *   connectionState: 'Connected' | 'Disconnected';
   *   etag: string;
   *   clients: [
   *     {
   *     clientId: string;
   *     displayName: string;
   *     clientType: string;
   *     connected: boolean;
   *       clientDictionary: {
   *         terminalName: string;
   *         terminalId: string;
   *        }
   *     } 
   *   ]
   *   properties: {
   *     reported: {
   *       TerminalId: string;
   *       TerminalName: string;
   *       Environment: string;
   *       Target: string;
   *       FilteredRoutes: string;
   *       LastTimeConnectedAt: string;
   *       LastEmployee: string;
   *       Routes: string;
   *       InstanceName: string;
   *     }
   *   }
   * }} iotDevice
  */
  constructor(iotDevice) {
    super(iotDevice);

    const { FilteredRoutes, LastEmployee, Routes } = iotDevice.properties.reported;

    this.routeFilteringActive = FilteredRoutes === 'true';
    this.routes = Routes.split(',');
    this.lastEmployee = LastEmployee;
  }
}

class SingleClient {
  /**
   * @param {{ 
   *  connectorId: string;
   *  displayName: string;
   *  connectionState: 'Connected' | 'Disconnected';
   *  platform: string;
   * }} device
   * 
   * @param {{
   *  clientId: string;
   *  displayName: string;
   *  clientType: string;
   *  lastConnected: string; 
   *  clientDictionary: {
   *    terminalId: string;
   *    terminalName: string; 
   * }
   * 
   * }}client
  */

  constructor(client, device) {
      const { connectorId, displayName: deviceDisplayName, connectionState, platform } = device;
      const { clientId, displayName, clientType, lastConnected} = client
      const { terminalId, terminalName} = client.clientDictionary

      this.connectorId = connectorId;
      this.deviceDisplayName = deviceDisplayName;
      this.isOnline = connectionState === 'Connected'; //TODO:Switch to using client status needs to be added to API
      this.platform = platform?.charAt(0).toUpperCase() + platform?.slice(1);
      this.terminalName = terminalName;
      this.terminalId = terminalId
      
      this.clientId = clientId
      this.displayName = displayName
      this.clientType = clientType
      this.lastConnected = lastConnected
  }

  /**
   * Gets a group key for this device under a given grouping mode.
   * @param {GroupingMode} groupingMode the active grouping mode
   */
  GetGroupKey(groupingMode) {
    switch (groupingMode) {
      case GroupingMode.Status: return this.isOnline ? "Online" : "Offline";
      case GroupingMode.Target: return this.target ?? "Unknown Target";
      case GroupingMode.Client: return this.clientType ?? "Unknown Client";
      case GroupingMode.Terminal: return (`${this.terminalName} (${this.terminalId})`) ?? "No Terminal";
      default: throw Error(`Invalid grouping mode: ${groupingMode}`);
    }
  }
}

class SingleClientDetail {
  /**
   * @param {{ 
  *  connectorId: string;
  *  displayName: string;
  *  connectionState: 'Connected' | 'Disconnected';
  *  platform: string;
  * }} device
  * 
  * @param {{
  *  clientId: string;
  *  displayName: string;
  *  clientType: string;
  *  connected: string;
  *  lastConnected: string; 
  *  clientDictionary: {
  *    target: string;
  *    terminalId: string;
  *    terminalName: string; 
  *    lastEmployee: string;
  *    routes: Array;
  *    filteredRoutes: boolean;
  * }
  * 
  * }}client
 */

  constructor(device, client) {
    const { connectorId, displayName: deviceDisplayName, connectionState: deviceConnectedState, platform } = device;
    const { clientId, displayName, clientType, connected, lastConnected} = client
    const { target, terminalId, terminalName, lastEmployee, routes, filteredRoutes } = client.clientDictionary

    this.connectorId = connectorId;
    this.deviceDisplayName = deviceDisplayName;
    this.isDeviceOnline = deviceConnectedState === 'Connected';
    this.platform = platform?.charAt(0).toUpperCase() + platform?.slice(1);
    this.terminalName = terminalName;
    this.terminalId = terminalId
    
    this.clientId = clientId
    this.displayName = displayName
    this.clientType = clientType
    this.lastConnected = lastConnected
    this.clientConnected = connected

    this.target = target
    this.lastEmployee = lastEmployee
    this.routes = routes
    this.filteredRoutes = filteredRoutes
  }
}

const GetClients = async () => {
  const targetUrl = process.env.REACT_APP_SMS_API_URL + "/api/connectors";
  const response = await axios({
    method: "get",
    url: targetUrl,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "*/*",
      "x-functions-key": process.env.REACT_APP_SMS_API_KEY,
    },
  });

  const filteredDevices = response.data.filter((device) => device !== null && device.clients.length > 0);
  const clients = filteredDevices.flatMap((device) =>
    device.clients.map((client) => new SingleClient(client, device))
  );
  return clients;
}

const GetClient = async (connectorId, clientId) => {
  const targetUrl = process.env.REACT_APP_SMS_API_URL + "/api/connectors/" + connectorId;
  const response = await axios({
    method: "get",
    url: targetUrl,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "*/*",
      "x-functions-key": process.env.REACT_APP_SMS_API_KEY,
    },
  });
  const client = response.data.clients.find(client => client.clientId === clientId);

  return new SingleClientDetail(response.data, client);
}

const GetSelectedClientAndOther = async (connectorId, clientId) => {
  const targetUrl = process.env.REACT_APP_SMS_API_URL + "/api/connectors/" + connectorId;
  const response = await axios({
    method: "get",
    url: targetUrl,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "*/*",
      "x-functions-key": process.env.REACT_APP_SMS_API_KEY,
    },
  });
  const selectedClient = response.data.clients.find(client => client.clientId === clientId);
  var otherClients = response.data.clients.filter(client => client.clientId !== clientId);
  if (otherClients !== undefined) {
    otherClients = otherClients.map(e => new SingleClientDetail(response.data, e));
  }
  return {
    selectedClient: new SingleClientDetail(response.data, selectedClient), 
    otherClients: otherClients
  }
}

const ConvertToClient = (device) => {
  switch (device.clients.clientType) {
    case "Connector": return new ConnectorDetail(device); //we are not using it any more 
    case "PointSort": return new PointSortClient(device);
    default: return undefined; // indicates that this device is not valid and should be skipped
  }
}

const UpdateClient = async (clientId, desired) => {
  const targetUrl = process.env.REACT_APP_SMS_API_URL + "/api/data-clients/" + clientId;
  const response = await axios({
    method: "patch",
    url: targetUrl,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "*/*",
      "x-functions-key": process.env.REACT_APP_SMS_API_KEY,
    },
    data: {
      properties: {
        desired
      }
    }
  });
  return response;
}
const GetTerminals = async () => {
  const targetUrl = process.env.REACT_APP_SMS_API_URL + "/api/terminals";
  const response = await axios({
    method: "get",
    url: targetUrl,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "*/*",
      "x-functions-key": process.env.REACT_APP_SMS_API_KEY,
    },
  });
  return (response.data);
}
const GetTerminalRoutes = async (terminalId) => {
  const targetUrl = process.env.REACT_APP_SMS_API_URL + "/api/terminals/" + terminalId + "/routes";
  console.log(targetUrl);
  const response = await axios({
    method: "get",
    url: targetUrl,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "*/*",
      "x-functions-key": process.env.REACT_APP_SMS_API_KEY,
    },
  });
  return (response.data);
}


export { ConnectorDetail, PointSortClient, BaseClient, GetClients, GetClient, GetSelectedClientAndOther, UpdateClient, GetTerminals, GetTerminalRoutes };
