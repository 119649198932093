import { Link } from 'react-router-dom';

import DeviceStatus from '../components/DeviceStatus';
import PlatformInfo from '../components/PlatformInfo';
import './DeviceCard.scss';

// Renders the children, wrapped in a <Link> if `to` is defined
const OptionalLink = ({ to, children }) => {
  if (to) {
    return (<>
      <Link to={to} style={{ textDecoration: 'unset', color: 'unset' }}>
        {children}
      </Link>
    </>);
  } else {
    return (<>{children}</>);
  }
};

const DeviceCard = ({ text: { heading, firstsubheading, secondsubheading, detail }, isOnline, linkTo }) => {
  const className = linkTo ? 'deviceCard isLink' : 'deviceCard';

  return (<>
    <div id='cardContainer' className={className}>
      <OptionalLink to={linkTo}>
        <div id='cardPadding' className="d-flex flex-nowrap p-3 ms-1">
          <div id="textContainer" className="flex-fill" style={{ minWidth: 0 }}>
            {/* minWidth: 0 allows text to be truncated. */}
            {/* See: https://stackoverflow.com/questions/59976201/bootstrap-text-does-not-truncate-when-using-flexbox */}
            {heading !== undefined &&
              <p className='heading fw-semibold text-truncate m-0 p-0'>{heading}</p>}

            {firstsubheading !== undefined &&
              <p className='firstsubheading text-truncate m-0 p-0'>{firstsubheading}</p>}

            {secondsubheading !== undefined &&
              <p className='secondsubheading text-truncate m-0 p-0'> {secondsubheading}</p>}
          </div>
          <div className='statusContainer' >
            <DeviceStatus online={isOnline}  className='deviceStatus'/>
            {detail !== undefined &&
              <p className='detail fw-semibold text-truncate'>Platform: {PlatformInfo(detail)}</p>}
          </div>
        </div>
      </OptionalLink>
    </div>
  </>);
};

export default DeviceCard;
