import React from 'react';
import WidgetContainer from '../../components/WidgetContainer';
import { Link } from 'react-router-dom';
import { Spinner, Table } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import {
  GetClients,
} from '../../IoTDevices/clients';

const LastConnectedClientsWidget = ({
  selectedTerminals,
  onChangeTerminals,
  allTerminals
}) => {
  const noUnderlineStyle = { textDecoration: 'none' };
  
  const [clientsByLastConnected, setClientsByLastConnected] = useState();

  useEffect(() => {
    GetClients().then((clients) =>
      setClientsByLastConnected(
        clients
          .filter(
            (client) =>
              selectedTerminals.length < 1 || // Include all if no selectedTerminals
              selectedTerminals.includes(client.terminalId)
          )
          .map(obj => { 
            return { ...obj, lastConnectedDateTime: new Date(obj.lastConnected) } })
          .sort(
            (clientA, clientB) =>
              clientB.lastConnectedDateTime - clientA.lastConnectedDateTime
          )
          .slice(0, 5)
      )
    );
  }, [selectedTerminals]);


  if (!clientsByLastConnected && !allTerminals) {
    return (
      <WidgetContainer title="Recently Connected Clients">
        <div className="d-flex align-items-center justify-content-center">
          <Spinner animation="border" />
        </div>
      </WidgetContainer>
    );
  } else {
    return (
      <WidgetContainer
        title="Recently Connected Clients"
        allTerminals={allTerminals}
        selectedTerminals={selectedTerminals}
        onChangeTerminals={onChangeTerminals}
      >
        <Table>
          <thead>
            <tr>
              <th>Client Type</th>
              <th>Device Name</th>
              <th>Connected Time</th>
            </tr>
          </thead>
          <tbody>
            {clientsByLastConnected 
              ? clientsByLastConnected.map((client) => {
                  return (
                    <tr key={client.connectorId}>
                      <td>
                        <Link
                          style={noUnderlineStyle}
                          to={`/clients/${client.connectorId}/${client.clientId}`}
                        >
                          {client.displayName}
                        </Link>
                      </td>
                      <td>{client.connectorId}</td>
                      <td>{client.lastConnected !== undefined ? client.lastConnected.replace('T', ' ').slice(0, 19) : ""}</td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </Table>
      </WidgetContainer>
    );
  }
};

export default LastConnectedClientsWidget;