import React from 'react';
import { Badge } from 'react-bootstrap';

import './DeviceStatus.css';

const DeviceStatus = ({ online }) => {
  const statusText = (online && 'Online') || 'Offline';
  const statusClass = (online && 'online') || '';

  return (<>
    <div className='d-block'>
      <Badge pill bg='no-thanks' className={`deviceStatus ${statusClass}`}>{statusText}</Badge>
    </div>
  </>);

}


export default DeviceStatus;