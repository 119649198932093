import { MsalAuthProvider, LoginType } from 'react-aad-msal';

// Msal Configurations
const config = {
    auth: {
        authority: process.env.REACT_APP_AAD_AUTHORITY,
        clientId: process.env.REACT_APP_AAD_CLIENT_ID,
        redirectUri: window.location.origin + '/auth'
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false
    }
};

// Authentication Parameters
const authenticationParameters = {
    scopes: [
        'user.read'
    ]
}

// Options
const options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin + '/auth'
}

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)