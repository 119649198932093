import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Navigate, useParams } from "react-router-dom";
import { GetSelectedClientAndOther } from "../IoTDevices/clients";
import ClientDetailsPage from "./ClientDetailsPage";
import { createConnection } from "../SignalR/signalr";
import { useAuth } from "../ProtectedRoutes";

const ClientAppPage = () => {
  const { connectorId, clientId } = useParams();
  const [client, setClient] = useState(undefined);
  const [otherClients, setOtherClients] = useState([]);
  const [clientLoaded, setClientLoaded] = useState(false);
  const userId = useAuth().userId;
  const [signalrConnection, setSignalrConnection] = useState(undefined);
  const [signalRData, setSignalRData] = useState({
    subject: "",
    eventType: "",
  });

  // This useEffect hook handles loading the client and keeping
  // track of the loading state.
  useEffect(() => {
    if (signalRData.subject && signalRData.eventType && signalRData.eventType !== "deviceTwinUpdated") {
        const updatedClient = updateClientInfo({
            eventType: signalRData.eventType,
            deviceId: signalRData.subject.slice(8),
            client: client
          });
        setClient({ ...updatedClient });
    } else {
      GetSelectedClientAndOther(connectorId, clientId)
      .then((res) => {
        setClient(res.selectedClient);
        setOtherClients(res.otherClients);
        setClientLoaded(true);
      })
      .catch((err) => {
        // If client is loaded and undefined, we assume an error occurred.
        setClient(undefined);
        setOtherClients([]);
        setClientLoaded(true);
        console.error(err);
      });
    }
    
  }, [connectorId, signalRData]);
  //Establish signalR connection and notification
  useEffect(() => {
    const connection = createConnection(userId);
    setSignalrConnection(connection);
  }, [userId]);

  useEffect(() => {
    if (signalrConnection) {
      signalrConnection
        .start()
        .then(() => {
          signalrConnection.on("clientConnected", (event) => {
            setSignalRData({
              subject: event.Subject,
              eventType: "clientConnected"
            });
          });
          signalrConnection.on("clientDisconnected", (event) => {
            setSignalRData({
              subject: event.Subject,
              eventType: "clientDisconnected"
            });
          });
          signalrConnection.on("clientCreated", (event) => {
            setSignalRData({
              subject: event.Subject,
              eventType: "clientCreated"
            });
          });
          signalrConnection.on("clientDeleted", (event) => {
            setSignalRData({
              subject: event.Subject,
              eventType: "clientDeleted"
            });
          });
          signalrConnection.on("deviceTwinUpdated", (event) => {
            setSignalRData({
              subject: event.Subject,
              eventType: "deviceTwinUpdated",
            });
          });
        })
        .catch((e) => console.error(e));
    }
  }, [signalrConnection]);

  const navToClientsPage = <Navigate to={"/clients"} />;

  // Did the client load ok? If not, go back to clients page
  if (clientLoaded && !client) {
    return navToClientsPage;
  }

  // While loading, show a spinner centered in both axes
  if (!clientLoaded) {
    return (
      <>
        <div className="d-flex flex-column align-items-center justify-content-center h-100">
          <Spinner animation="border" role="status" />
          <p>Loading, please wait.</p>
        </div>
      </>
    );
  }

  const updateClientInfo = ({eventType, client}) => {
    if (eventType === "clientConnected") {
      client.isDeviceOnline = true;
    } else if (eventType === "clientDisconnected") {
      client.isDeviceOnline = false;
    }
    return client;
  }
  
  return <ClientDetailsPage client={client} otherClients={otherClients}/>;
};

export default ClientAppPage;
