import Toast from "react-bootstrap/Toast";
import { ToastContainer } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { createConnection } from "./signalr";
import { useAuth } from "../ProtectedRoutes";

const DataClientsToast = (props) => {
  const [toastBody, setToastBody] = useState("");
  const [toastHeader, setToastHeader] = useState("");
  const [newMessage, setNewMessage] = useState(false);
  const toggleShowToast = () => setNewMessage(!newMessage);
  const [signalrConnection, setSignalrConnection] = useState(undefined);
  const userId = useAuth().userId;
  const [signalRData, setSignalRData] = useState({
    subject: "",
    eventType: "",
  });
  useEffect(() => {
    props.setSignalRData({
      subject: signalRData.subject,
      eventType: signalRData.eventType,
    });
  }, [newMessage]);

  useEffect(() => {
    const connection = createConnection(userId);
    setSignalrConnection(connection);
  }, [userId]);

  useEffect(() => {
    if (signalrConnection) {
      signalrConnection
        .start()
        .then(() => {
          signalrConnection.on("clientConnected", (event) => {
            //Event Data does not get forwarded in SignalR message so
            //identify the device by extracting the deviceId from the subject { Subject: "devices/25F67704A9F7" }
            setToastBody(`Data client ${event.Subject.slice(8)} connected`);
            setToastHeader("Data Client Connected");
            setNewMessage(true);
            setSignalRData({
              subject: event.Subject,
              eventType: "clientConnected",
            });
          });
          signalrConnection.on("clientDisconnected", (event) => {
            setToastBody(`Data Client ${event.Subject.slice(8)} disconnected`);
            setToastHeader("Data Client Disconnected");
            setNewMessage(true);
            setSignalRData({
              subject: event.Subject,
              eventType: "clientDisconnected",
            });
          });
          signalrConnection.on("clientCreated", (event) => {
            setToastBody(
              `Data Client ${event.Subject.slice(8)} registered to IoTHub`
            );
            setToastHeader("Data Client Created");
            setNewMessage(true);
            setSignalRData({
              subject: event.Subject,
              eventType: "clientCreated",
            });
          });
          signalrConnection.on("clientDeleted", (event) => {
            setToastBody(
              `Data Client ${event.Subject.slice(8)} deleted from IoTHub`
            );
            setToastHeader("Data Client Deleted");
            setNewMessage(true);
            setSignalRData({
              subject: event.Subject,
              eventType: "clientDeleted",
            });
          });
          signalrConnection.on("deviceTwinUpdated", (event) => {
            setToastBody(`Data Client ${event.DeviceId} Updated`);
            setToastHeader("Device Twin Updated");
            setNewMessage(true);
            setSignalRData({
              subject: event.Subject ?? `devices/${event.DeviceId}`,
              eventType: event.EventType ?? "deviceTwinUpdated",
            });
          });
        })
        .catch((e) => console.log("Connection failed: ", e));
    }
  }, [signalrConnection]);
  return (
    <>
      <ToastContainer position="top-center">
        <Toast show={newMessage} onClose={toggleShowToast} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toastHeader}</strong>
            <small>Just Now</small>
          </Toast.Header>
          <Toast.Body>{toastBody}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export { DataClientsToast };
