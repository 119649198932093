import React from 'react';
import PageHeader from '../components/PageHeader';
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import "./css/ApiReferencePage.css";

const ApiReferencePage = () => {
  const swaggerUrl = process.env.REACT_APP_SMS_API_URL + "/api/swagger.json";
  return (
    <>
      <PageHeader title='Api Reference' />
      <div className="p-4">
        <SwaggerUI url={swaggerUrl} />
      </div>
    </>
  );

};

export default ApiReferencePage;
