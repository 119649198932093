import { ReactComponent as SortMarshalLogo } from '../assets/SortMarshal_icononly.svg';
import PurolabBlack from '../assets/purolab_black.png';

const PDLFooter = () => {
  // Would love to have not needed magic numbers for both axes in the SVG sizes but Chrome be Chrome
  return (
    <footer className='position-absolute bottom-0 start-0 end-0 border-top py-2 px-4 m-0' style={{ backdropFilter: 'blur(1px)', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
      <div className="d-flex flex-nowrap justify-content-end align-items-center">
        <SortMarshalLogo title="Sort Marshal" className="m-0 p-0" height="1.5em" width="1.5em" />

        <p className='m-0 p-0 fs-7'>SortMarshal made with love by</p>

        {/* For images, height in ems MUST be specified in the style tag. Inline height is relative to the image, not the block. */}
        {/* Using ems in the inline height gives you a tiny image. */}
        <img src={PurolabBlack} style={{ height: '1.25em' }} className='m-0 mb-1 p-0 ps-1' alt="Purolator Digital Lab" />
      </div>
    </footer>
  );
};

export default PDLFooter;