import Toast from "react-bootstrap/Toast";
import { ToastContainer } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { createConnection } from "./signalr";
import { useAuth } from "../ProtectedRoutes";
import { useNavigate } from "react-router-dom";

const DataClientDetailToast = ({ deviceId }) => {
  const [toastBody, setToastBody] = useState("");
  const [toastHeader, setToastHeader] = useState("");
  const [newMessage, setNewMessage] = useState(false);
  const toggleShowToast = () => setNewMessage(!newMessage);
  const [signalrConnection, setSignalrConnection] = useState(undefined);
  const userId = useAuth().userId;
  const navigate = useNavigate();

  useEffect(() => {
    const connection = createConnection(userId);
    setSignalrConnection(connection);
  }, [userId]);

  useEffect(() => {
    if (signalrConnection) {
      signalrConnection
        .start()
        .then(() => {
          signalrConnection.on("clientConnected", (event) => {
            if (event.Subject.slice(8) === deviceId) {
              //Event Data does not get forwarded in SignalR message so
              //identify the device by extracting the deviceId from the subject { Subject: "devices/25F67704A9F7" }
              setToastBody(`Data client ${event.Subject.slice(8)} connected`);
              setToastHeader("Data Client Connected");
              setNewMessage(true);
            }
          });
          signalrConnection.on("clientDisconnected", (event) => {
            if (event.Subject.slice(8) === deviceId) {
              setToastBody(
                `Data Client ${event.Subject.slice(8)} disconnected`
              );
              setToastHeader("Data Client Disconnected");
              setNewMessage(true);
            }
          });
          signalrConnection.on("clientDeleted", (event) => {
            if (event.Subject.slice(8) === deviceId) {
              setToastBody(
                `Data Client ${event.Subject.slice(8)} deleted from IotHub`
              );
              setToastHeader("Data Client Deleted");
              setNewMessage(true);
              // Redirect to "Client Apps" page if client had been deleted
              // Add delay so user will able to see the toast message
              setTimeout(function() {
                navigate("/clients", { replace: true });
              }, 1500);
            }
          });
          signalrConnection.on("clientCreated", (event) => {
            if (event.Subject.slice(8) === deviceId) {
              setToastBody(
                `Data Client ${event.Subject.slice(8)} registered to IoTHub`
              );
              setToastHeader("Data Client Created");
              setNewMessage(true);
            }
          });
          signalrConnection.on("deviceTwinUpdated", (event) => {
            setToastBody(`Data Client ${event.DeviceId} Updated`);
            setToastHeader("Device Twin Updated");
            setNewMessage(true);
          });
        })
        .catch((e) => console.log("Connection failed: ", e));
    }
  }, [signalrConnection, deviceId]);

  return (
    <>
      <ToastContainer position="top-center">
        <Toast show={newMessage} onClose={toggleShowToast} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toastHeader}</strong>
            <small>Just Now</small>
          </Toast.Header>
          <Toast.Body>{toastBody}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export { DataClientDetailToast };
