import React from 'react';
import axios from 'axios';
import WidgetContainer from '../../components/WidgetContainer';
import { Spinner, Table } from 'react-bootstrap';
import { useState, useEffect } from 'react';

const RSATableSummaryWidget = ({selectedTerminals}) => {
  const [terminalSummary, setTerminalSummary] = useState([]);

  useEffect(() => {
    const getWidgetData = async () => {
      const environment = process.env.REACT_APP_ENVIRONMENT;
      const targetUrl = process.env.REACT_APP_SMS_API_URL + "/api/widget-data/rsa-summary" + (environment ? `?environment=${environment}` : '');
      const response = await axios({
        method: "get",
        url: targetUrl,
        headers: {
          "Access-Control-Allow-Origin": "*",
          Accept: "*/*",
          "x-functions-key": process.env.REACT_APP_SMS_API_KEY,
        },
      });
      setTerminalSummary(response.data);
    }
    getWidgetData();
  }, [])

  if (!terminalSummary) {
    return (
      <WidgetContainer title="Recently Connected Clients">
        <div className="d-flex align-items-center justify-content-center">
          <Spinner animation='border' />
        </div>
      </WidgetContainer>
    )
  }
  
  const filteredTerminal = terminalSummary
    .filter(
      (client) =>
        selectedTerminals.length < 1 || // Include all if no selectedTerminals
        selectedTerminals.includes(client.terminalId)
    );

  return (
    <WidgetContainer title="RSA Table Summary">
      <Table>
        <thead>
          <tr>
            <th>Terminal Id</th>
            <th>Pin Count</th>
            <th>Route Count</th>
          </tr>
        </thead>
        <tbody>
          {filteredTerminal.length > 0 ?
            filteredTerminal.map((terminal) =>
              <tr key={terminal.terminalId}>
                <td>{terminal.terminalId}</td>
                <td>{terminal.pinCount}</td>
                <td>{terminal.uniqueRouteCount}</td>
              </tr>
            ) : <><tr><td className="no-data-text">No data</td></tr></>
          }  
        </tbody>
      </Table>
    </WidgetContainer>
  );
}

export default RSATableSummaryWidget;