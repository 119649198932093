import React, { useState } from "react";
import DeviceCard from "./DeviceCard";
import { faAngleDown, faAngleUp } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "react-bootstrap";
import './ClientGroup.scss';

const ClientGroupDetails = ({groupName, clients}) => {
    const [isOpen, setIsOpen] = useState(true);
  
    const toggleView = () => {
      setIsOpen(!isOpen);
    };
  
    return (
      <>
        <Row onClick={toggleView} className="group-name mt-2 mb-0">
            <Col><p>{groupName}</p></Col>
            <Col className="collapse-icon"><FontAwesomeIcon icon={isOpen ? faAngleDown : faAngleUp} /></Col>
        </Row><br/>
        <div id="groupContainer" className="mb-4">
            <div className="w-100 d-flex flex-wrap gap-4">
            {isOpen && clients.map((client, index) => (
                <DeviceCard
                    key={index}
                    linkTo={`/clients/${client.connectorId}/${client.clientId}`}
                    text={cardText(client)}
                    isOnline={client.isOnline} />
            ))}
            </div>
        </div>
    </>
    );
  };

  const cardText = (client) => {
    if (client) {
      return {
        heading: client.displayName,
        firstsubheading: client.terminalName,
        detail: client.platform,
        secondsubheading: client.terminalId,
      };
    } else {
      return { heading: "Unknown Client", detail: client.connectorId };
    }
  };
  
  export default ClientGroupDetails;