import { Navigate, Outlet } from 'react-router-dom';
import { authProvider } from './Auth/authProvider';
import { AuthenticationState } from 'react-aad-msal';

const useAuth = () => {
  const user = { loggedIn: false, userId: undefined };
  if (authProvider.authenticationState === AuthenticationState.Authenticated) {
    user.loggedIn = true;
    user.userId = authProvider.getAccountInfo().account.accountIdentifier;
  } else {
    user.loggedIn = false;
  }

  return user;
};

const ProtectedRoutes = () => {
  const isAuth = useAuth();
  if (isAuth.loggedIn) {
    // outlet is basically like saying return nothing but overwrite with next thing(pass-through)
    // as opposed to returning a specific page which will force the ui to go there
    return <Outlet />
  } else {
    return <Navigate to="/" />
  }
};

export { useAuth };
export default ProtectedRoutes;